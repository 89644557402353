import React from 'react';

import Wrapper from '../components/Wrapper';
import FormPage from '../components/FormPage';

const form = ({ data }) => {
  return (
    <Wrapper className="page">
      <FormPage />
    </Wrapper>
  );
};

export default form;
