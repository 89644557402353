import { initial } from 'lodash';
import React, { useState, useRef } from 'react';
import * as qs from 'query-string';

import { useForm } from './hooks';

const FormPage = () => {
  const [formState, setFormState] = useState({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const fields = [
    { type: 'text', name: 'name' },
    { type: 'email', name: 'email' },
    { type: 'textarea', name: 'message' },
  ];
  let initialValues = {};

  fields.forEach(field => {
    initialValues[field.name] = '';
  });
  console.log(initialValues);
  const { handleChange, inputs } = useForm(initialValues);
  console.log(inputs);

  // function handleChange(e) {
  //   let { value, name, type } = e.target;
  //   if (type === 'number') {
  //     value = parseInt(value);
  //   }
  //   if (type === 'file') {
  //     [value] = e.target.files;
  //   }
  //   setInputs({
  //     // copy the existing state
  //     ...inputs,
  //     [name]: value,
  //   });
  // }

  const formRef = useRef();

  return (
    <div>
      {success && <p>THank you!</p>}
      {error && (
        <div>
          <p>Something went wrong!</p>
          <pre>
            <code>{error}</code>
          </pre>
        </div>
      )}
      <form
        name="sample"
        data-netlify="true"
        ref={formRef}
        onSubmit={e => {
          e.preventDefault();
          console.log(formState);
          let formData = new FormData(formRef.current);

          console.log(new URLSearchParams(formData).toString());
          console.log(qs.stringify({ ...inputs, 'form-name': 'sample' }));
          fetch(window.location.pathname, {
            method: 'POST',
            header: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: qs.stringify({ ...inputs, 'form-name': 'sample' }),
          })
            .then(() => {
              setSuccess(true);
            })
            .catch(error => {
              setError(error);
            });
        }}
      >
        <p>
          <label>
            Your Name:{' '}
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={inputs['name']}
            />
          </label>
        </p>
        <p>
          <label>
            Your Email:{' '}
            <input
              type="email"
              name="email"
              onChange={handleChange}
              value={inputs['email']}
            />
          </label>
        </p>
        <p>
          <label>
            Your Role:{' '}
            <select name="role[]" multiple>
              <option value="leader">Leader</option>
              <option value="follower">Follower</option>
            </select>
          </label>
        </p>
        <p>
          <label>
            Message:{' '}
            <textarea
              name="message"
              onChange={handleChange}
              value={inputs['message']}
            ></textarea>
          </label>
        </p>
        <input type="hidden" name="form-name" value="sample" />
        <p>
          <button type="submit">Send</button>
        </p>
      </form>
    </div>
  );
};

export default FormPage;
